<template>
  <UiModal ref="modal" :is-blocking="true" @close="closeModal">
    <div class="pt-10">
      <div
        class="mx-auto flex size-20 items-center justify-center rounded-full bg-primary-dark/20 shadow-custom"
      >
        <UpdateIcon class="size-12 text-primary" />
      </div>
      <h3
        class="mx-auto mt-5 max-w-xs text-pretty text-center text-xl font-bold"
      >
        Update Available!
      </h3>
      <p
        class="mx-auto mt-1 max-w-xs text-center text-sm text-light-text dark:text-dark-light-text"
      >
        A new version of the Luckee App is available.
      </p>
      <UiButton rounded raised center class="mt-6" @click="closeModal">
        Update App
      </UiButton>
    </div>
  </UiModal>
</template>

<script setup lang="ts">
import UiModal from '~/components/ui/UiModal.vue'
import UpdateIcon from '@/assets/icons/update.svg?component'

// Modal
const modal = ref<InstanceType<typeof UiModal> | null>(null)

function open() {
  modal.value?.open()
}
function closeModal() {
  modal.value?.close()
}

defineExpose({
  open,
  closeModal,
})
</script>
