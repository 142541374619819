import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m17.5 20.1l-1.45-1.45q-.15-.15-.35-.15t-.35.15t-.15.35t.15.35l1.95 1.95q.15.15.325.213t.375.062t.375-.062t.325-.213l1.95-1.95q.15-.15.15-.35t-.15-.35t-.35-.15t-.35.15L18.5 20.1v-3.6q0-.2-.15-.35T18 16t-.35.15t-.15.35zM12 12.85q.275 0 .525-.075t.475-.2l5.075-2.925q.35-.2.45-.612t-.1-.763t-.6-.45t-.75.1L12 10.85L6.925 7.925q-.35-.2-.75-.1t-.6.45t-.087.763t.462.612L11 12.575q.225.125.475.2t.525.075m-9 3.125v-7.95q0-.55.263-1T4 6.3l7-4.025q.25-.125.488-.2T12 2t.525.075t.475.2L20 6.3q.475.275.738.725t.262 1v3.4q0 .45-.375.7t-.8.125t-.888-.187T18 12q-2.9 0-4.95 2.05T11 19v.513q0 .237.05.437q.1.625-.363.975T9.726 21L4 17.7q-.475-.275-.737-.725t-.263-1M18 24q-2.075 0-3.537-1.463T13 19t1.463-3.537T18 14t3.538 1.463T23 19t-1.463 3.538T18 24"
    }, null, -1)
  ])))
}
export default { render: render }